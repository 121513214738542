
// Vue reactivity
import { computed } from 'vue';

// components
import { IonPage, IonGrid, IonHeader, IonToolbar, IonTitle, IonContent,
        IonSpinner, IonList, IonButtons, IonBackButton, } from '@ionic/vue';

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'TermsAndConditionsPage',
  components: { IonPage, IonGrid, IonHeader, IonToolbar, IonTitle, IonContent,
                IonSpinner, IonList, IonButtons, IonBackButton, },
  setup() {
    // methods or filters
    const { t } = useI18n();
    const store = useStore();
    const loading = computed(() => store.state.loadingAppPublicData);
    const companyInfo = computed(() => store.state.companyInfo);

    // 3. return variables & methods to be used in template HTML
    return {
      t,
      
      loading, companyInfo,
    }
  }
}
